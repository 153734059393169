import { readFromLocalStorage, writeToLocalStorage } from '@jouzen/ecom-utils';
import { configureStore } from '@reduxjs/toolkit';

import accountReducer from './account/accountSlice';
import orderReducer from './order/orderSlice';

const localStorageMiddleware = ({ getState }: any) => {
  return (next: (arg0: any) => any) => (action: any) => {
    const result = next(action);
    writeToLocalStorage('applicationState', JSON.stringify(getState()));
    return result;
  };
};

const reHydrateStore = () => {
  const localState = readFromLocalStorage('applicationState');
  if (localState) {
    return JSON.parse(localState);
  }
};

export const makeStore = () => {
  return configureStore({
    reducer: {
      //@ts-ignore
      order: orderReducer,
      account: accountReducer,
    },
    preloadedState: reHydrateStore(),
    //@ts-ignore
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(localStorageMiddleware),
  });
};

export type AppStore = ReturnType<typeof makeStore>;
export type RootState = ReturnType<AppStore['getState']>;
export type AppDispatch = AppStore['dispatch'];
